// Libs
import React, { useState, useEffect, useContext } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import PromptServiceContent from 'components/promptService/promptServiceContent';
import Qa from 'components/Qa';
import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';
import { HeaderContext } from 'context/header';

// Styles
import { ContentText } from 'components/Accordion/style.js';

const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};

  margin-bottom: 0rem;

  @media ${device.laptop} {
    flex-direction: column;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: -1rem;
    margin-bottom: 0.5rem;
  }
`;

const Part = styled.div`
  display: ${props => props.isDisplay};
  grid-column: ${props => props.gridColumn};
  border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  padding-top: ${props => props.paddingTop && '-10px'};
  padding-bottom: ${props => props.paddingBottom && '60px'};
  border-top: ${props => props.borderTop && '1px solid #C6C8CC'};

  @media ${device.mobile} {
    padding-bottom: 25px;
    padding-bottom: ${props =>
      props.paddingBottomWithUl && props.paddingBottomWithUl};
    paddding-top: -8px;
  }

  :last-child {
    border-bottom: ${props => props.borderBottom && 'unset'};
  }

  section :first-child {
    padding: ${props =>
      props.notPadding
        ? '70px 0 0 !important'
        : props.notPaddingTop && '0 0 70px !important'};
    p {
      padding-left: ${props => props.notLeft && '0'};
    }

    @media ${device.laptop} {
      width: ${props => props.notPaddingTop && '100%'};
      padding: ${props =>
        props.notPadding
          ? '30px 0 0 !important'
          : props.notPaddingTop && '30px 0 !important'};
      margin: 0;
      border-bottom: ${props => props.notPaddingTop && 'unset'};
    }

    @media ${device.tablet} {
      padding: ${props => props.notPaddingTop && '0 0 30px !important'};
    }
  }

  section :last-child {
    padding: ${props => props.shortPadding && '20px 0 70px'};
    @media ${device.laptop} {
      padding: ${props => props.shortPadding && '0 0 30px'};
    }
  }

  section {
    div {
      div {
        h2 {
          width: ${props => props.largeTitle && '28rem'};

          @media ${device.laptop} {
            width: 100%;
          }
        }
      }
    }
  }

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIBelaVista ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-top: ${props => props.paddingTop && '30px'};
    padding-bottom: ${props => props.notPaddingTop && '30px'};
    border-bottom: ${props =>
      props.borderBottom || props.test
        ? 'unset'
        : props.notPaddingTop && '1px solid #C6C8CC'};

    section {
      div {
        display: ${props => props.paddingTop && 'flex'};
      }
    }
  }

  @media ${device.tablet} {
    padding-top: ${props => props.paddingTop && '0'};
    border-top: ${props => props.paddingTop && 'unset'};
  }

  @media ${device.laptop} {
    border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
    :last-child {
      border-bottom: ${props => props.borderBottom && 'unset'};
    }
    margin-top: ${({ marginTopLap }) => (marginTopLap ? '1.875rem' : '0')};
  }
`;

export const Title = styled.h2`
  margin-top: 2rem;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 1.5rem;

  @media ${device.laptop} {
    margin-top: 1.9375rem;
    margin-bottom: 1.125rem;
    font-size: 1.125rem;
  }
`;

const StyledQA = styled(Qa)`
  ${ContentText} {
    ul li {
      margin-bottom: 0rem;
    }
  }

  div:last-child {
    @media ${device.mobile} {
      margin-top: 2rem;
    }
  }

  @media ${device.mobile} {
    section {
      div {
        margin-top: 0rem !important;
      }
    }
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderPage = (page, isDesktop) => {
  const formattedQaPergunta = getMarkdown(
    page,
    'ckwgv4ei0944b0b73ri1jfprg',
    true
  )
    .replace(/(<div id=.*[\n|\r]\n)/i, '')
    .split(/(##.*\n)/gim)
    .filter(el => el)
    .map((item, index) =>
      index % 2 === 0 ? `${item.replace(/\n/gim, '')}` : item
    )
    .slice(0, 6)
    .join('\n');

  const qaContent = {
    autor: [],
    doctors: [],
    title: null,
    pergunta: formattedQaPergunta || '',
  };

  const featuredMarketingContent = page[0]?.featuredMarketing;
  const components = page[0]?.components ?? [];

  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('7.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  return (
    <>
      {featuredMarketingContent && (
        <FeaturedMarketing content={featuredMarketingContent} />
      )}
      <GridContainer isDesktop={isDesktop}>
        <Part
          gridColumn={'2 / -2'}
          paddingTop
          paddingBottom
          borderBottom
          largeTitle
          notLeft
          marginTopLap
        >
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckwdk9g28ryh40a17q8zjpupd', true)}
            fragments={getMarkdown(page, 'ckwdk9g28ryh40a17q8zjpupd')}
            isShowing
            withoutBorderTop
            containerWidth="50%"
            containerPaddingDesk="4.375rem 0 1.5rem 0"
            containerPaddingLaptop="0.125em 0 0.4rem 0"
            containerWidthLap="100%"
            notTitleBold
            isBottom
            boxContentPaddingBottomLaptop="0.375rem"
            containerPaddingLap="2.375rem 0"
          />
        </Part>
        <Part
          gridColumn={'2/-2'}
          largeTitle
          borderBottom
          paddingBottomWithUl="2px"
        >
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckwdqg6kwv98w0d69wyeakhgh', true)}
            fragments={getMarkdown(page, 'ckwdqg6kwv98w0d69wyeakhgh')}
            padding="4.375rem 0 3.125rem"
            notTitleBold
            isBottom
            isFacilities
            titleUlDisplay="none"
            isOncologia
            isDoacao
            withoutBorderTop
            contentMarginBottom="0"
            contentTagLiImgMargin="0 0.625rem 0 0"
            isNutrologia
            contentPChild12InfoCard
            contentPChild13EmailCard
            contentTagEmColor="#000"
            boxContentPaddingBottomLaptop="0"
            titleWidth="18.75rem"
            contentHasExternalLink={true}
            numberInformation
            isShowing
          />
        </Part>
        <Part gridColumn={'2/-2'} notLeft>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckwdzvy28101h0a177veubj05', true)}
            fragments={getMarkdown(page, 'ckwdzvy28101h0a177veubj05')}
            padding="4.375rem 0 3.125rem"
            marginListDesk="0rem"
            notTitleBold
            isBottom
            isFacilities
            titleUlDisplay="none"
            isOncologia
            isDoacao
            withoutBorderTop
            contentMarginBottom="0"
            contentTagUlListStyleType="none"
            contentTagLiImgMargin="0 0.625rem 0 0"
            contentUlListWithMultiLine
            isNutrologia
            contentPChild12InfoCard
            contentPChild13EmailCard
            contentTagEmColor="#000"
            boxContentPaddingBottomLaptop="0"
            titleWidth="18.75rem"
            contentHasExternalLink={true}
            numberInformation
            contentTagImgTransform="translate(0, 80%)"
          />
        </Part>
        {qaContent.pergunta && (
          <Part gridColumn={'2 / -2'} paddingBottom borderTop>
            <StyledQA
              title={'Perguntas Frequentes'}
              data={qaContent}
              showFaqLink="/faq/doacoes/"
              showFaqLinkOnClick={() => localStorage.setItem('page', 'doacoes')}
            />
          </Part>
        )}
      </GridContainer>
    </>
  );
};
const SejaUmDoador = ({ page }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  const getImage = getMarkdown(page, 'ckwdjcs4wrob40d69dodr43u7', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />{' '}
      {renderPage(page, isDesktop)}{' '}
    </Layout>
  );
};

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckwdhvhxce2ny0a7055fukzzq" }) {
                featuredMarketing(locales: [pt_BR, en]) {
                  id
                  type
                  breadcrumb
                  backgroundColor {
                    hex
                  }
                  images {
                    handle
                    width
                    height
                    url
                  }
                  title
                  fragments {
                    id
                    name
                    type
                    markdown
                  }
                }
                fragments {
                  id
                  name
                  singletexts
                  markdown
                  localizations {
                    singletexts
                    markdown
                    datafragment
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                qa(locales: pt_BR) {
                  titulo
                  pergunta
                  autor
                  doctors {
                    active
                    assignment
                    name
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <SejaUmDoador page={response.gcms.site.pages} />;
      }}
    />
  );
};
